@import "../../../utils/scss/variables";

.productTableHeaderStyle {
  display: flex !important;
  background: #f9fafb !important;
  justify-content: space-between !important;
  position: relative;
  left: 0vw;
  border: 1px solid #eaecf0 !important;
  align-items: center !important;
  border-radius: 0.34vw 0.6vh 0vw 0vh !important;
  width: 52.08vw !important;
  height: 8vh;
}
.productTableRow {
  padding: 1.94vh 1.66vw 1.94vh 0vw;
  border: 1px solid #eaecf0;
  justify-content: space-between;
}
.productTitle {
  position: relative;
  right: 45vw;
  white-space: nowrap;
  width: 8vw;
  font-size: 1.11vw;
}

.rowCheckbox {
  font-size: 1.11vw;
  width: 2vw;
  height: 1vw;
  cursor: pointer;
}
.productImage {
  position: relative;
  right: 41vw;
  top: 0.2vh;
  width: 4.86vw;
  height: 5.35vh;
  border-radius: $base-border-radius; /* border-m */
}

.productNoImage {
  position: relative;
  right: 39.5vw;
  top: 1.5vh;
  width: 4.86vw;
  height: 5.35vh;
  font-size: 1.11vw;
  border-radius: $base-border-radius; /* border-m */
}

.productID {
  position: relative;
  right: 36vw;
  font-size: 1.11vw;
}
.productUrl {
  position: relative;
  right: 29vw;
  font-size: 1.11vw;
}

.tableHeadStyle {
  border-bottom: none !important;
  background-color: rgb(249, 250, 251) !important;
  font-family: $base-font-family-sans-serif !important;
  display: flex;
  align-items: center;
  font-size: $base-font-size-normal-text !important;
}

.disabled_row {
  opacity: 0.4;
  pointer-events: none;
  padding: 1.94vh 1.66vw 1.94vh 0vw !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

/* Group 1204 */
.threeDotGroupContainer {
  position: relative;
  top: 4vh;
  left: 0.31vw;
  width: 1.98vw;
  height: 2.43vh;
}

/* Dots three vertical 5 */
.leftIcon {
  position: absolute;
  top: 1.21vh;
  left: 0vw;
  width: 1.38vw;
  height: 2.43vh;
  fill: $base-box-shadow-color; /* neutral-900 */
}

/* Dots three vertical 6 */
.rightIcon {
  position: absolute;
  top: 1.21vh;
  left: 0.55vw;
  width: 1.38vw;
  height: 2.43vh;
  fill: $base-box-shadow-color; /* neutral-900 */
}

.videoUseCase {
  position: relative;
  bottom: 0vh;
  right: 54vw;
  opacity: 1;
  border-bottom: none !important;

  select {
    width: 8.5vw;
    height: 4.38vh;
    padding-left: 0.83vw;
    padding-right: 2.36vw;
    font-family: $base-font-family-sans-serif; /* Body */
    font-size: $base-font-size-normal-text;
    color: $base-box-shadow-color; /* neutral-900 */
    background: $base-table-button-BG-color; /* transparent */
    border-radius: 0.41vw; /* border-m */
    border-width: 0.06vw;
    border-color: #9095a1ff; /* neutral-500 */
    outline: none;
  }
  svg {
    position: absolute;
    top: 1.46vh;
    right: 0.83vw;
    width: 1.11vw;
    height: 1.94vh;
    fill: $base-box-shadow-color; /* neutral-900 */
  }
  /* hover */
  input:hover {
    color: $base-box-shadow-color; /* neutral-900 */
    border-color: #6f7787ff; /* neutral-550 */
  }
  /* focused */
  input:focused {
    color: $base-box-shadow-color; /* neutral-900 */
    border-color: #9095a1ff; /* neutral-500 */
  }
  /* disabled */
  input:disabled {
    color: $base-box-shadow-color; /* neutral-900 */
    border-color: #9095a1ff; /* neutral-500 */
  }
}

.videoUseCase :disabled + .icon,
.videoUseCase :disabled + .icon + .icon {
  fill: $base-box-shadow-color; /* neutral-900 */
}

/* trash */
.deleteIcon {
  position: relative;
  bottom: 0vh;
  right: 34.1vw;
  width: 1.66vw;
  height: 2.92vh;
  cursor: pointer;
  fill: $base-box-shadow-color; /* neutral-900 */
}

.videoText {
  position: relative;
  top: 4.6vh;
  right: 56vw;
  font-family: $base-font-family-sans-serif !important;
  font-size: $base-font-size-normal-text !important;
  line-height: $base-line-height-normal-text !important;
  width: 8.13vw;
  color: $base-box-shadow-color;
  display: flex;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: none !important;
}

.videoTitleText {
  position: relative;
  bottom: 0vh;
  right: 55vw;
  opacity: 1;
  border-bottom: none !important;

  input {
    width: 8.88vw;
    height: 4.38vh;
    padding-left: 0.83vw;
    padding-right: 0.83vw;
    font-family: $base-font-family-sans-serif; /* Body */
    font-size: $base-font-size-normal-text;
    background: $base-input-focus-BG-color; /* white */
    border-radius: 0.41vw; /* border-m */
    border-width: 0.06vw;
    border-color: #9095a1ff; /* neutral-500 */
    outline: none;
  }
  /* hover */
  input:hover {
    color: $base-box-shadow-color; /* neutral-900 */
    background: $base-input-focus-BG-color; /* white */
    border-color: #6f7787ff; /* neutral-550 */
  }
  /* focused */
  input:focused {
    color: $base-box-shadow-color; /* neutral-900 */
    background: $base-input-focus-BG-color; /* white */
    border-color: #9095a1ff; /* neutral-500 */
  }
  /* disabled */
  input:disabled {
    color: $base-box-shadow-color; /* neutral-900 */
    background: $base-input-focus-BG-color; /* white */
    border-color: #9095a1ff; /* neutral-500 */
  }
}

.videoTitleText :disabled + .icon,
.videoTitleText :disabled + .icon + .icon {
  fill: $base-box-shadow-color; /* neutral-900 */
}

.tableRowStyle {
  padding: 1.94vh 1.66vw 1.94vh 0vw !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.tableHeaderStyle {
  display: flex !important;
  background: #f9fafb !important;
  justify-content: space-between !important;
  position: relative;
  left: 0.2vw;
  border: 1px solid #eaecf0 !important;
  align-items: center !important;
  border-radius: 5px 5px 0px 0px !important;
  width: 58.58vw !important;
}

.edittableContainer {
  overflow-x: hidden !important;
  max-height: 52.54vh !important;
}

.uploadVideoThumbnailButton {
  position: relative;
  right: 53.5vw;
  height: 3.38vh;
  top: 5vh;
  width: 5.2vw;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1vw;
  line-height: 3.41vh;
  color: $base-input-focus-BG-color; /* white */
  opacity: 1;
  border: none;
  cursor: pointer;
  border-radius: 0.41vw; /* border-m */
}

.uploadVideoGifButton {
  position: relative;
  right: 41.5vw;
  height: 3.38vh;
  bottom: 2vh;
  width: 5.2vw;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1vw;
  line-height: 3.41vh;
  color: $base-input-focus-BG-color; /* white */
  opacity: 1;
  border: none;
  cursor: pointer;
  border-radius: 0.41vw; /* border-m */
}

.uploadVideoThumbnailButton:disabled {
  cursor: auto;
}
.uploadVideoGifButton:disabled {
  cursor: auto;
}

.VideoThumbnailReplaceButton {
  position: relative;
  right: 47.5vw;
  height: 3.38vh;
  top: 1.5vh;
  width: 5.2vw;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1vw;
  line-height: 3.41vh;
  color: $base-input-focus-BG-color; /* white */
  opacity: 1;
  border: none;
  cursor: pointer;
  border-radius: 0.41vw; /* border-m */
}
.VideoThumbnailReplaceButton:disabled {
  cursor: auto;
}

.VideoGifReplaceButton {
  position: relative;
  right: 35.5vw;
  height: 3.38vh;
  bottom: 5.5vh;
  width: 5.2vw;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1vw;
  line-height: 3.41vh;
  color: $base-input-focus-BG-color; /* white */
  opacity: 1;
  border: none;
  cursor: pointer;
  border-radius: 0.41vw; /* border-m */
}
.VideoGifReplaceButton:disabled {
  cursor: auto;
}

/********************************************/

/* Container 7794 */
.deleteAlertDialogContainer {
  top: 10.59vh;
  left: 15.76vw;
  width: 21.11vw;
  height: 34.1vh;
  background: $base-input-focus-BG-color; /* white */
  border-radius: 0.55vw; /* border-l */
  box-shadow: 0px 0.27vw 1.09vh $base-box-shadow-color,
    0px 0px 2px $base-box-shadow-color; /* shadow-m */
}

/* Button 4024 */
.deleteLogo {
  position: absolute;
  top: 2.92vh;
  left: 9.02vw;
  width: 3.05vw;
  height: 5.35vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $base-dialog-danger-color; /* danger-500 */
  background: #fef0f5ff; /* danger-100 */
  opacity: 1;
  border: none;
  border-radius: 1.52vw;
  gap: 0vw;
}
.deleteLogo .icon {
  width: 1.38vw;
  height: 2.43vh;
  fill: $base-dialog-danger-color; /* danger-500 */
}
/* Hover */
.deleteLogo:hover {
  color: $base-dialog-danger-color; /* danger-500 */
  background: #fddde7ff; /* danger-150 */
}
/* Pressed */
.deleteLogo:hover:active {
  color: $base-dialog-danger-color; /* danger-500 */
  background: #fcc9daff; /* danger-200 */
}
/* Disabled */
.deleteLogo:disabled {
  opacity: 0.4;
}

.deleteWarningText {
  position: absolute;
  top: 10.23vh;
  left: 5.97vw;
  height: 4.38vh;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.66vw;
  line-height: 4.38vh;
  color: $base-box-shadow-color; /* neutral-900 */
}

.deleteWarningSubText {
  position: absolute;
  top: 15.34vh;
  left: 1.66vw;
  width: 17.77vw;
  height: 8.03vh;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-box-shadow-color; /* neutral-900 */
}

/* Button 4023 */
.deleteCancelButton {
  position: absolute;
  top: 26.79vh;
  left: 1.66vw;
  width: 8.33vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-troopod-color; /* primary-500 */
  background: $base-table-button-BG-color; /* transparent */
  opacity: 1;
  border-radius: 0.41vw; /* border-m */
  border-width: 0.06vw;
  border-color: $base-troopod-color; /* primary-500 */
  cursor: pointer;
  border-style: solid;
}
/* Hover */
.butdeleteCancelButtonton:hover {
  color: #7211e7ff; /* primary-600 */
  background: $base-table-button-BG-color; /* transparent */
}
/* Pressed */
.deleteCancelButton:hover:active {
  color: $base-button-active-BG-color; /* primary-700 */
  background: $base-table-button-BG-color; /* transparent */
}
/* Disabled */
.deleteCancelButton:disabled {
  opacity: 0.4;
}

/* Button 4022 */
.deleteConfirmButton {
  position: absolute;
  top: 26.79vh;
  left: 11.11vw;
  width: 8.33vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.41vw;
  border-width: 0.06vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-input-focus-BG-color; /* white */
  background: $base-dialog-danger-color; /* danger-500 */
  opacity: 1;
  border: none;
  cursor: pointer;
  border-style: solid;
}
/* Hover */
.deleteConfirmButton:hover {
  color: $base-input-focus-BG-color; /* white */
  background: #f0145dff; /* danger-600 */
}
/* Pressed */
.deleteConfirmButton:hover:active {
  color: $base-input-focus-BG-color; /* white */
  background: #d50e50ff; /* danger-650 */
}
/* Disabled */
.deleteConfirmButton:disabled {
  opacity: 0.4;
}

/**********************************************/

/* Story Live Alert Box */

/* Container 7793 */
.liveAlertContainer {
  top: 10.59vh;
  left: 15.76vw;
  width: 21.11vw;
  height: 33.61vh;
  background: $base-input-focus-BG-color; /* white */
  border-radius: 0.55vw; /* border-l */
  box-shadow: 0px 0.27vw 1.09vh $base-box-shadow-color,
    0px 0px 2px $base-box-shadow-color; /* shadow-m */
}

/* Button 4235 */
.liveConfirmImg {
  position: absolute;
  top: 2.92vh;
  left: 9.02vw;
  height: 5.35vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $base-confirm-Button_color; /* success-750 */
  background: #effdf2ff; /* success-100 */
  opacity: 1;
  border: none;
  border-radius: 1.52vw;
  gap: 0vw;
}
.liveConfirmImg .icon {
  width: 1.38vw;
  height: 2.43vh;
  fill: $base-confirm-Button_color; /* success-750 */
}
/* Hover */
.liveConfirmImg:hover {
  color: $base-confirm-Button_color; /* success-750 */
  background: #c9f8d1ff; /* success-200 */
}
/* Pressed */
.liveConfirmImg:hover:active {
  color: $base-confirm-Button_color; /* success-750 */
  background: #a3f4b1ff; /* success-300 */
}
/* Disabled */
.liveConfirmImg:disabled {
  opacity: 0.4;
}

.liveAlertHeadingText {
  position: absolute;
  top: 10.23vh;
  left: 5.97vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.66vw;
  line-height: 4.38vh;
  color: $base-box-shadow-color; /* neutral-900 */
}

.liveAlertSubText {
  position: absolute;
  top: 14.85vh;
  left: 1.66vw;
  width: 17.77vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-box-shadow-color; /* neutral-900 */
}

/* Button 4235 */
.liveCancelButton {
  position: absolute;
  top: 26.3vh;
  left: 1.66vw;
  width: 8.33vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-dialog-cancel-Button; /* neutral-600 */
  background: $base-input-BG-color; /* neutral-200 */
  opacity: 1;
  border: none;
  border-radius: 0.41vw; /* border-m */
}
/* Hover */
.liveCancelButton:hover {
  color: $base-dialog-cancel-Button; /* neutral-600 */
  background: #dee1e6ff; /* neutral-300 */
}
/* Pressed */
.liveCancelButton:hover:active {
  color: $base-dialog-cancel-Button; /* neutral-600 */
  background: #cfd2daff; /* neutral-350 */
}
/* Disabled */
.liveCancelButton:disabled {
  opacity: 0.4;
}

/* Button 4235 */
.liveConfirmButton {
  position: absolute;
  top: 26.3vh;
  left: 11.11vw;
  width: 8.33vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-input-focus-BG-color; /* white */
  background: $base-troopod-color; /* primary-500 */
  opacity: 1;
  border: none;
  border-radius: 0.41vw; /* border-m */
}
/* Hover */
.liveConfirmButton:hover {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-hover-BG-color; /* primary-600 */
}
/* Pressed */
.liveConfirmButton:hover:active {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-active-BG-color; /* primary-700 */
}
/* Disabled */
.liveConfirmButton:disabled {
  opacity: 0.4;
}

/* Container 7902 */
.logoutAlertDialogContainer {
  position: fixed;
  top: 86.6vh;
  left: 1.11vh;
  width: 11vw;
  height: 5.72vh;
  background: $base-input-focus-BG-color; /* white */
  border-radius: 0.41vw; /* border-m */
  box-shadow: 0px 4px 9px $base-box-shadow-color,
    0px 0px 2px $base-box-shadow-color; /* shadow-m */
}
/* Container 7902 */
.conlogoutAlertDialogContainer:after {
  content: "";
  position: absolute;
  left: 0.83vw;
  bottom: -0.48vh;
  width: 0.72vw;
  height: 1.27vh;
  background: $base-input-focus-BG-color; /* white */
  border-top: 1px solid $base-input-focus-BG-color;
  border-left: 1px solid $base-input-focus-BG-color;
  transform: rotate(-135deg);
}

/* Sidebar menu 2 */
.sidebar_menu {
  position: absolute;
  top: 1.33vh;
  left: 1vw;
  width: 9.02vw;
  height: 4vh;
  display: flex;
  flex-direction: row;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 0.97vw;
  line-height: 2.67vh;
  cursor: pointer;
  opacity: 1;

  .arrow_icon {
    width: 1.66vw;
    height: 2.92vh;
    fill: $base-sub-text-color;
  }
}

.sidebar_menu_text {
  display: contents;
  font-size: 1.25vw;
}
.tableContainer {
  border: 0.08vw solid $base-table-header-color !important;
}

.tableHederContainer {
  background: $base-table-header-color;
  height: 12.18vh;
}
.dashboardTableRow {
  font-size: $base-font-size-heading-large !important;
}

.tableHeaderText {
  font-family: $base-font-family-sans-serif !important; /* Body */
  line-height: $base-line-height-normal-text !important;
  color: $base-sidemenu-color !important; /* neutral-600 */
  font-size: $base-font-size-heading-large !important;
  font-weight: $base-font-weight-semibold !important;
}
.tableBodyText {
  font-family: $base-font-family-sans-serif !important; /* Body */
  line-height: $base-line-height-normal-text !important;
  color: $base-box-shadow-color !important; /* neutral-600 */
  font-size: $base-font-size-heading-large !important;
}
@mixin statusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding-left: 0.41vw;
  padding-right: 0.41vw;
  border-radius: 0.97vw;
}
.liveStatusContainer {
  @include statusContainer;
  background-color: $base-table-success-container-color;
  color: $base-table-success-color;
}
.draftStatusContainer {
  @include statusContainer;
  background-color: $base-table-error-container-color;
  color: $base-table-error-color;
}
.actionMenuCardContainer {
  display: flex;
  align-items: center;
  width: 14.16vw;
  height: 14.86vh;
  padding: 1.04vw;
  font-family: $base-font-family-sans-serif !important; /* Body */
  line-height: $base-line-height-normal-text !important;
  color: $base-box-shadow-color !important; /* neutral-600 */
  font-size: $base-font-size-heading-large !important;
  ul {
    width: 100%;
    li {
      cursor: pointer;
      &:hover {
        background-color: $base-table-header-color !important;
      }
    }
  }
}

/**********************************************************/

/* Draft Story */

/* Button 4238 */
.draftAlert {
  position: absolute;
  top: 24px;
  left: 120px;
  height: 44px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #956107ff; /* warning-700 */
  background: #fef9efff; /* warning-100 */
  opacity: 1;
  border: none;
  border-radius: 22px;
  gap: 0px;
}
.draftAlert .icon {
  width: 20px;
  height: 20px;
  fill: #956107ff; /* warning-700 */
}
/* Hover */
.draftAlert:hover {
  color: #956107ff; /* warning-700 */
  background: #fce4bbff; /* warning-200 */
}
/* Pressed */
.draftAlert:hover:active {
  color: #956107ff; /* warning-700 */
  background: #f9cf87ff; /* warning-300 */
}
/* Disabled */
.draftAlert:disabled {
  opacity: 0.4;
}

.draftAlertHeading {
  position: absolute;
  top: 84px;
  left: 52px;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 24px;
  line-height: 36px;
  color: $base-box-shadow-color; /* neutral-900 */
}

.draftAlertSubHeading {
  position: absolute;
  top: 122px;
  left: 24px;
  width: 256px;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 14px;
  line-height: 22px;
  color: $base-box-shadow-color; /* neutral-900 */
  text-align: center;
}

/**********************************************/

.existStoryWarningHeading {
  position: absolute;
  top: 10.23vh;
  left: 8vw;
  height: 4.38vh;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.66vw;
  line-height: 4.38vh;
  color: $base-box-shadow-color; /* neutral-900 */
}

.existStoryWarningSubHeading {
  position: absolute;
  top: 15.34vh;
  left: 1.66vw;
  width: 17.77vw;
  height: 8.03vh;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-box-shadow-color; /* neutral-900 */
  text-align: center;
}

.existStoryWarningButton {
  position: absolute;
  top: 26.79vh;
  left: 6.5vw;
  width: 8.33vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.41vw;
  border-width: 0.06vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-input-focus-BG-color; /* white */
  background: $base-dialog-danger-color; /* danger-500 */
  opacity: 1;
  border: none;
  cursor: pointer;
  border-style: solid;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Mulish:wght@200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

//colors constants
$base-BG-color: #f7f1feff;
$base-box-shadow-color: #171a1f;
$base-text-color: #323743ff;
$base-sub-text-color: #232525ff;
$base-troopod-color: #8e3ff1ff;
$base-error-message-color: #ff0000;
$base-input-BG-color: #f3f4f6ff;
$base-input-focus-BG-color: #ffffffff;
$base-input-color: #bdc1caff;
$base-button-active-BG-color: #570db2ff;
$base-button-hover-BG-color: #7211e7ff;
$base-otp-input-border-color: #9095a1ff;
$base-icon-BG-color: #ffffff00;
$base-check-icon-fill-color: #f45088ff;
$base-profile-BG-color: #000000ff;
$base-sidebar-BG-color: #f9f6fa;
$base-sidemenu-color: #565d6dff;
$base-table-header-color: #f8f0feff;
$base-table-success-color: #107e22ff;
$base-table-success-container-color: #effdf2ff;
$base-table-error-color: #F45088FF;
$base-table-error-container-color: #FEF0F5FF;
$base-table-button-BG-color:#00000000;
$base-confirm-Button_color:#107e22ff;
$base-dialog-danger-color:#f45088ff;
$base-dialog-cancel-Button:#565d6dff;
$base-input-hover-border-color:#6f7787ff;

//fonts constants

$base-font-family-sans-serif: Montserrat;
$base-font-weight-bold: 700;
$base-font-weight-semibold: 600;
$base-font-weight-normal: 400;

//font-sizes constants

$base-font-size-normal-text: 0.97vw;
$base-font-size-label: 1.11vw;
$base-font-size-heading-base: 0.83vw;
$base-font-size-heading-large: 0.9rem;
$base-button-font-size-text: 1.25vw;

//line-height constants

$base-line-height-label: 3.16vh;
$base-line-height-normal-text: 2.67vh;

//border-radius constants
$base-border-radius: 0.83vw;
$base-input-border-radius: 1.52vw;

//padding constants

$base-input-padding-left: 1.11vw;
$base-input-padding-right: 1.11vw;

//mixin

@mixin inputStyle() {
  width: 26.66vw;
  height: 5.35vh;
}

@mixin passwordInputStyle {
  width: 24.66vw;
  height: 5.35vh;
}

/* Container 7917 */
.StoryDialogContent { 
    position: relative;
    width: 60.33vw; 
    height: 83.19vh;
    background: #F7F1FEFF; /* primary-100 */
    border-radius: 0.83vw; /* border-xl */
    border-width: 0.06vw; 
    border-color: #8E3FF1FF; /* primary-500 */
    border-style: solid; 
    overflow: auto;
    box-shadow: 0px 1.18vw 4.26vh #171a1f, 0px 0px 2px #171a1f; /* shadow-xl */
  }
  /* Container 7917 */
  .StoryDialogContent:after {
    content: ''; 
    position: absolute; 
    top: 0.24vh; 
    left: 36.52vw; 
    width: 0vw; 
    height: 0vh; 
    background: #F7F1FEFF; /* primary-100 */
    border-top: 1px solid [object Object]; 
    border-left: 1px solid [object Object]; 
    transform: rotate(45deg); 
  }

  /* Button 4278 */
.pushStarters_UploadVideoButton {
  position: absolute; 
  top: 13.27vh; 
  left: 20.41vw; 
  height: 4.38vh; 
  padding: 0 0.83vw; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  font-family: Montserrat; /* Body */
  font-size: 1.25vw; 
  line-height: 3.41vh; 
  color: #FFFFFFFF; /* white */
  background: #8E3FF1FF; /* primary-500 */
  opacity: 1; 
  border: none; 
  border-radius: 0.41vw; /* border-m */
}
/* Hover */
.pushStarters_UploadVideoButton:hover {
  color: #FFFFFFFF; /* white */
  background: #7211E7FF; /* primary-600 */
}
/* Pressed */
.pushStarters_UploadVideoButton:hover:active {
  color: #FFFFFFFF; /* white */
  background: #570DB2FF; /* primary-700 */
}
/* Disabled */
.pushStarters_UploadVideoButton:disabled {
  opacity: 0.4; 
}

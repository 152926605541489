@import "../../../utils/scss/variables";

.dashboardSidebarContainer {
  position: absolute;
  top: 0vh;
  left: 0vw;
  width: 4.16vw;
  height: 100vh;
  background: $base-sidebar-BG-color; /* tertiary3-500 */
  border-radius: 0vw;
  box-sizing: border-box;
  border: 1px solid #d3d3d3;
  overflow-x: hidden;
  box-shadow: 0px 0px 2px $base-box-shadow-color,
    0px 0px 2px $base-box-shadow-color; /* shadow-xs */
}

.activeTab {
  color: $base-troopod-color !important;
  border-right: 0.41vw solid $base-troopod-color !important;
  transition: all ease 0.2s !important;
}

.childContainer {
  margin-left: auto;
  margin-right: auto;
}

.troopodIcon {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: 0.73vh;
  left: 0.55vw;
  width: 3.05vw;
  height: 5.35vh;
  border-radius: 0.41vw; /* border-m */
}

/* Collapsed sidebar menu 5 */
.collapsed_sidebar_menu {
  position: absolute;
  top: 14.12vh;
  left: 0vw;
  width: 4.16vw;
  height: 33.73vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-heading-base;
  line-height: 2.43vh;
  opacity: 1;
  border-radius: 0vw;
}
/* Collapsed sidebar menu 5 - Icon */
.collapsed_sidebar_menu svg {
  width: 1.94vw;
  height: 3.41vh;
  margin: 1.04vw;
  cursor: pointer;
}

/* Collapsed sidebar menu 5 - Item */
.collapsed-sidebar-menu .collapsed-sidebar-menu-item {
  padding: 1.38vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $base-sidemenu-color; /* neutral-600 */
  background: $base-profile-BG-color; /* transparent */
  cursor: pointer;
  white-space: nowrap;
}
/* Collapsed sidebar menu 5 - Selected item */
.collapsed-sidebar-menu .collapsed-sidebar-menu-item.selected {
  position: relative;
  font-weight: bold;
  color: $base-troopod-color; /* primary-500 */
  background: $base-profile-BG-color; /* transparent */
}
/* Collapsed sidebar menu 5 - Selected item */
.collapsed-sidebar-menu .collapsed-sidebar-menu-item.selected:hover:active {
  color: $base-troopod-color; /* primary-500 */
  background: $base-profile-BG-color; /* transparent */
}

.topBarContainer {
  position: absolute;
  top: 0vw;
  left: 4.16vw;
  width: 95.83vw;
  height: 14.37vh;
  background: $base-input-focus-BG-color !important; /* white */
  border-radius: 0px;
  border: 1px solid #d3d3d3;
  box-shadow: 0px 0px 1px $base-box-shadow-color,
    0px 0px 2px $base-box-shadow-color; /* shadow-xs */
}
.dashboardHeading {
  position: absolute;
  top: 0.73vh;
  left: 1.73vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 2.22vw;
  line-height: 5.84vh;
  font-weight: 800;
  color: $base-troopod-color; /* primary-500 */
}

/* Button 4058 */
.addButton {
  position: absolute;
  top: 8.28vh;
  left: 80.76vw;
  width: 10.41vw;
  height: 4.87vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-button-font-size-text;
  line-height: 3.41vh;
  color: $base-input-focus-BG-color; /* white */
  background: $base-troopod-color; /* primary-500 */
  opacity: 1;
  border: none;
  border-radius: 0.41vw; /* border-m */
  cursor: pointer;
  gap: 0.41vw;
}
.addButton .icon {
  width: 18px;
  height: 18px;
  fill: $base-input-focus-BG-color; /* white */
}
/* Hover */
.addButton:hover {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-hover-BG-color; /* primary-600 */
}
/* Pressed */
.addButton:hover:active {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-active-BG-color; /* primary-700 */
}
/* Disabled */
.addButton:disabled {
  opacity: 0.4;
}

.profileIcon {
  position: absolute;
  top: 93.5vh;
  left: 0.83vw;
  width: 2.5vw;
  height: 4.38vh;
  background: #ddc5fbff; /* primary-200 */
  opacity: 1;
  overflow: hidden;
  border-radius: 1.25vw;
}
.profileIcon img {
  width: 2.5vw;
  height: 4.38vh;
}
.profileIcon .badge {
  width: 0.62vw;
  height: 1.09vh;
  border-radius: 0.31vw;
}
.profileIcon.active .badge {
  background: $base-profile-BG-color; /* transparent */
  opacity: 0;
  border-width: 0.1vw;
  border-color: $base-input-focus-BG-color;
}
.profileIcon.inactive .badge {
  background: $base-profile-BG-color; /* transparent */
  opacity: 0;
  border-width: 0.1vw;
  border-color: $base-input-focus-BG-color;
}
.profileIcon.idle .badge {
  background: $base-profile-BG-color; /* transparent */
  opacity: 0;
  border-width: 0.1vw;
  border-color: $base-input-focus-BG-color;
}
.profileIcon.do_not_disturb .badge {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.62vw;
  line-height: 1.09vh;
  color: $base-input-focus-BG-color; /* white */
  background: $base-profile-BG-color; /* transparent */
  opacity: 0;
  border-width: 0.1vw;
  border-color: $base-input-focus-BG-color;
}

.drawerContainer {
  border-right: $base-input-focus-BG-color;
  background: #fdfcfa26;
  box-sizing: border-box;
  width: 4.16vw;
}
.headerMenu {
  position: absolute;
  top: 7vh;
  left: 25vw;
  width: 40vw;
  height: 4vh;
  display: flex;
  align-items: center;
  font-family: $base-font-family-sans-serif !important;
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
}
@mixin TabItem {
  font-family: $base-font-family-sans-serif !important;
  padding: 15px 18px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 12vw;
}
.TabItemActive {
  @include TabItem;
  color: $base-troopod-color !important; /* neutral-600 */
  font-weight: bold !important;
}
.TabItemInactive {
  @include TabItem;
  color: $base-text-color !important; /* neutral-600 */
}

@import "../../../utils/scss/variables";

.pushStarters_CreateStoryHeadingText {
  position: absolute;
  top: 14.98vh;
  left: 13.88vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 2.22vw;
  line-height: 5.84vh;
  color: $base-box-shadow-color; /* neutral-900 */
}

.pushStarters_StoryNameContainer {
  position: absolute;
  top: 30.2vh;
  left: 13.88vw;
  opacity: 1;

  input {
    width: 30.55vw;
    height: 4.38vh;
    padding-left: 0.83vw;
    padding-right: 0.83vw;
    font-family: $base-font-family-sans-serif; /* Body */
    font-size: 0.977vw;
    background: $base-input-focus-BG-color; /* white */
    border-radius: 0.41vw; /* border-m */
    border-width: 0.06vw;
    border-color: $base-otp-input-border-color; /* neutral-500 */
    outline: none;
  }
  .pushStarters_StoryNameLabel {
    font-size: $base-font-size-normal-text;
    line-height: 1.52vh;
    font-weight: $base-font-weight-bold;
    font-family: $base-font-family-sans-serif;
  }
  input:hover {
    color: $base-input-color; /* neutral-400 */
    background: $base-input-focus-BG-color; /* white */
    border-color: $base-input-hover-border-color; /* neutral-550 */
  }
  input:focused {
    color: $base-input-color; /* neutral-400 */
    background: $base-input-focus-BG-color; /* white */
    border-color: $base-otp-input-border-color; /* neutral-500 */
  }
  input:disabled {
    color: $base-input-color; /* neutral-400 */
    background: $base-input-focus-BG-color; /* white */
    border-color: $base-otp-input-border-color; /* neutral-500 */
  }
}

.pushStarters_StoryNameContainer :disabled + .icon,
.pushStarters_StoryNameContainer :disabled + .icon + .icon {
  fill: $base-box-shadow-color; /* neutral-900 */
}

/* Textarea 3 */
.pushStarters_StoryDescContainer {
  position: absolute;
  top: 41.77vh;
  left: 13.88vw;
  opacity: 1;

  textarea {
    width: 30.55vw !important;
    height: 4.62vh !important;
    padding-top: 0.85vh;
    padding-bottom: 0.85vh;
    padding-left: 0.83vw;
    padding-right: 0.83vw;
    font-size: $base-font-size-normal-text;
    font-family: $base-font-family-sans-serif;
    line-height: $base-line-height-normal-text;
    color: $base-input-color; /* neutral-400 */
    background: $base-input-focus-BG-color; /* white */
    border-radius: 0.41vw; /* border-m */
    border-width: 1px;
    border-color: $base-otp-input-border-color; /* neutral-500 */
  }
  .pushStarters_StoryDescLabel {
    font-size: $base-font-size-normal-text;
    line-height: $base-line-height-normal-text;
    font-weight: $base-font-weight-bold;
    font-family: $base-font-family-sans-serif;
  }
  /* hover */
  textarea:hover {
    background: $base-input-focus-BG-color; /* white */
    border-color: $base-input-hover-border-color; /* neutral-550 */
  }
  /* focused */
  textarea:focused {
    background: $base-input-focus-BG-color; /* white */
    border-color: $base-otp-input-border-color; /* neutral-500 */
    box-shadow: 0px 0px 5px $base-otp-input-border-color;
  }
  /* disabled */
  textarea:disabled {
    color: $base-box-shadow-color; /* neutral-900 */
    background: $base-input-focus-BG-color; /* white */
    border-color: $base-otp-input-border-color; /* neutral-500 */
  }
}

.pushStarters_EditStoryButton {
  position: absolute;
  top: 57.85vh;
  left: 21.73vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.25vw;
  line-height: 3.41vh;
  color: $base-troopod-color; /* primary-500 */
  background: $base-BG-color; /* primary-100 */
  opacity: 1;
  border: none;
  border-radius: 0.41vw; /* border-m */
}
/* Hover */
.pushStarters_EditStoryButton:hover {
  color: $base-troopod-color; /* primary-500 */
  background: #eadbfcff; /* primary-150 */
  cursor: pointer;
}
/* Pressed */
.pushStarters_EditStoryButton:hover:active {
  color: $base-troopod-color; /* primary-500 */
  background: #ddc5fbff; /* primary-200 */
  cursor: pointer;
}
/* Disabled */
.pushStarters_EditStoryButton:disabled {
  opacity: 0.4;
}

/* Button 4084 */
.pushStarters_CancelStoryButton {
  position: absolute;
  top: 75.03vh;
  left: 22.22vw;
  width: 6.25vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.25vw;
  line-height: 3.41vh;
  color: $base-troopod-color; /* primary-500 */
  background: $base-input-focus-BG-color; /* white */
  opacity: 1;
  border-radius: 0.41vw; /* border-m */
  border-width: 0.06vw;
  border-color: $base-troopod-color; /* primary-500 */
  border-style: solid;
  cursor: pointer;
}
/* Hover */
.pushStarters_CancelStoryButton:hover {
  color: $base-button-hover-BG-color; /* primary-600 */
  background: $base-input-focus-BG-color; /* white */
}
/* Pressed */
.pushStarters_CancelStoryButton:hover:active {
  color: $base-button-active-BG-color; /* primary-700 */
  background: $base-input-focus-BG-color; /* white */
}
/* Disabled */
.pushStarters_CancelStoryButton:disabled {
  opacity: 0.4;
}

/* Button 4085 */
.pushStarters_CreateStoryButton {
  position: absolute;
  top: 75.03vh;
  left: 29.86vw;
  width: 6.25vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.25vw;
  line-height: 3.41vh;
  color: $base-input-focus-BG-color; /* white */
  background: $base-troopod-color; /* primary-500 */
  opacity: 1;
  border: none;
  cursor: pointer;
  border-radius: 0.41vw; /* border-m */
}
/* Hover */
.pushStarters_CreateStoryButton:hover {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-hover-BG-color; /* primary-600 */
}
/* Pressed */
.pushStarters_CreateStoryButton:hover:active {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-active-BG-color; /* primary-700 */
}
/* Disabled */
.pushStarters_CreateStoryButton:disabled {
  opacity: 0.4;
}

/*********************************************************/

/* Product Page */

.pushStarters_ProductpageHeadingText {
  position: absolute;
  top: 0px;
  left: 11.18vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 2.22vw;
  line-height: 5.84vh;
  color: $base-box-shadow-color; /* neutral-900 */
}

.pushStarters_ProductpageSubHeadingText {
  position: absolute;
  top: 5.84vh;
  left: 17.29vw;
  width: 23.81vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 0.83vw;
  line-height: $base-line-height-normal-text;
  color: $base-sub-text-color; /* tertiary2-500 */
}

/* Textbox 2131 */
.searchTextBoxContainer {
  position: absolute;
  top: 10.96vh;
  left: 3.12vw;
  opacity: 1;

  input {
    width: 52.08vw;
    height: 4.87vh;
    padding-left: 2.36vw;
    padding-right: 0.83vw;
    font-family: $base-font-family-sans-serif; /* Body */
    font-size: $base-font-size-normal-text;
    background: $base-input-focus-BG-color; /* white */
    border-radius: 0.41vw; /* border-m */
    border-width: 0.06vw;
    border-color: $base-otp-input-border-color; /* neutral-500 */
    outline: none;
  }
  .pushStarters_SearchIcon {
    position: absolute;
    top: 1.46vh;
    right: 0.83vw;
    width: 1.11vw;
    height: 1.94vh;
    fill: $base-box-shadow-color; /* neutral-900 */
    left: 0.83vw;
  }

  .pushStarters_ClearIcon {
    position: absolute;
    top: 1.46vh;
    right: 0.83vw;
    width: 1.11vw;
    height: 1.94vh;
    fill: $base-box-shadow-color; /* neutral-900 */
    cursor: pointer;
    left: 49.83vw;
  }
  .pushStarters_CircularProgress {
    display: flex !important;
    justify-content: center !important;
  }
  /* hover */
  input:hover {
    color: $base-input-color; /* neutral-400 */
    background: $base-input-focus-BG-color; /* white */
    border-color: $base-input-hover-border-color; /* neutral-550 */
  }
  /* focused */
  input:focused {
    color: $base-input-color; /* neutral-400 */
    background: $base-input-focus-BG-color; /* white */
    border-color: $base-otp-input-border-color; /* neutral-500 */
  }
  /* disabled */
  input:disabled {
    color: $base-input-color; /* neutral-400 */
    background: $base-input-focus-BG-color; /* white */
    border-color: $base-otp-input-border-color; /* neutral-500 */
  }
}

.searchTextBoxContainer :disabled + .icon,
.searchTextBoxContainer :disabled + .icon + .icon {
  fill: $base-box-shadow-color; /* neutral-900 */
}

/* Table 93 */
.pushStarters_TableContainer {
  position: absolute;
  top: 16.44vh;
  left: 3.125vw;
  width: 52.08vw;
  max-height: 52.52vh;
  opacity: 1;
  border-radius: 0.41vw; /* border-m */
  box-shadow: none; /* shadow-none */
  overflow-y: auto;
}

.pushStarters_NoProductText {
  font-family: $base-font-family-sans-serif;
  display: flex;
  justify-content: center;
}

/* Button 4081 */
.pushStarters_ProductBackButton {
  position: absolute;
  top: 75.03vh;
  left: 22.22vw;
  width: 6.25vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.25vw;
  line-height: 3.41vh;
  color: $base-troopod-color; /* primary-500 */
  background: $base-input-focus-BG-color; /* white */
  opacity: 1;
  border-radius: 0.41vw; /* border-m */
  border-width: 0.06vw;
  border-color: $base-troopod-color; /* primary-500 */
  border-style: solid;
}
/* Hover */
.pushStarters_ProductBackButton:hover {
  color: $base-button-hover-BG-color; /* primary-600 */
  background: $base-input-focus-BG-color; /* white */
}
/* Pressed */
.pushStarters_ProductBackButton:hover:active {
  color: $base-button-active-BG-color; /* primary-700 */
  background: $base-input-focus-BG-color; /* white */
}
/* Disabled */
.pushStarters_ProductBackButton:disabled {
  opacity: 0.4;
}

/* Button 4080 */
.pushStarters_ProductNextButton {
  position: absolute;
  top: 75.03vh;
  left: 29.86vw;
  width: 6.25vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.25vw;
  line-height: 3.41vh;
  color: $base-input-focus-BG-color; /* white */
  background: $base-troopod-color; /* primary-500 */
  opacity: 1;
  border: none;
  border-radius: 0.41vw; /* border-m */
}
/* Hover */
.pushStarters_ProductNextButton:hover {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-hover-BG-color; /* primary-600 */
}
/* Pressed */
.pushStarters_ProductNextButton:hover:active {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-active-BG-color; /* primary-700 */
}
/* Disabled */
.pushStarters_ProductNextButton:disabled {
  opacity: 0.4;
}

/*********************************************************/

/* Add Videos Page */

.pushStarters_AddVideoHeadingText {
  position: absolute;
  top: 0vh;
  left: 22.5vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 2.22vw;
  line-height: 3.33vh;
  color: $base-box-shadow-color; /* neutral-900 */
}

.pushStarters_AddVideoSubHeadingText {
  position: absolute;
  top: 5.84vh;
  left: 15.55vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: #f4a61fff; /* warning-500 */
}

/* Container 8056 */
.pushStarters_UnboxingVideoContainer {
  position: absolute;
  top: 10.96vh;
  left: 4.16vw;
  width: 50vw;
  height: 30.88vh;
  background: $base-input-focus-BG-color; /* white */
  border-radius: 0.41vw; /* border-m */
  box-shadow: 0px 0px 1px $base-box-shadow-color,
    0px 0px 2px $base-box-shadow-color; /* shadow-xs */
  overflow-x: hidden;
  overflow-y: auto;
}

.pushStarters_UnboxingVideoHeadingText {
  position: absolute;
  top: 0vh;
  left: 2.5vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.8vw;
  line-height: 2.77vh;
  color: $base-box-shadow-color; /* neutral-900 */
}

.pushStarters_UnboxingVideoDetails {
  position: absolute;
  top: 4.48vh;
  left: 2.5vw;
  width: 44.44vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: 1.82vh;
  color: $base-box-shadow-color; /* neutral-900 */
}

.pushStarters_UnboxingUploadedVideoHeading {
  position: absolute;
  top: 19.31vh;
  left: 10.55vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-sub-text-color; /* tertiary2-500 */
}

.pushStarters_UnboxingUploadedVideoName {
  position: absolute;
  top: 22.18vh;
  left: 12.72vw;
  width: 22.36vw;
  font-family: $base-font-family-sans-serif;
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-box-shadow-color;
}
.pushStarters_UnboxingUploadedVideoSize {
  position: relative;
  bottom: 1.82vh;
  width: 5.55vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-otp-input-border-color; /* neutral-500 */
  margin-top: 0;
}
.videoIcon {
  position: absolute;
  top: 211px;
  left: 152px;
  width: 48px;
  height: 48px;
  border-radius: 0px;
}

/* Container 8053 */
.pushStarters_SpecsVideoContainer {
  position: absolute;
  top: 46.28vh;
  left: 4.16vw;
  width: 50vw;
  height: 30.88vh;
  background: $base-input-focus-BG-color; /* white */
  border-radius: 0.41vw; /* border-m */
  box-shadow: 0px 0px 1px $base-box-shadow-color,
    0px 0px 2px $base-box-shadow-color; /* shadow-xs */
  overflow-x: hidden;
  overflow-y: auto;
}

.pushStarters_SpecsVideoHeadingText {
  position: absolute;
  top: 0vh;
  left: 2.5vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.8vw;
  line-height: 4.87vh;
  color: $base-box-shadow-color; /* neutral-900 */
}

.pushStarters_SpecsVideoSubHeadingText {
  position: absolute;
  top: 5.48vh;
  left: 2.5vw;
  width: 44.44vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-box-shadow-color; /* neutral-900 */
}

/* Button 4279 */
.button {
  position: absolute;
  top: 13.27vh;
  left: 20.41vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.25vw;
  line-height: 3.41vh;
  color: $base-input-focus-BG-color; /* white */
  background: $base-troopod-color; /* primary-500 */
  opacity: 1;
  border: none;
  border-radius: 0.41vw; /* border-m */
}
/* Hover */
.button:hover {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-hover-BG-color; /* primary-600 */
}
/* Pressed */
.button:hover:active {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-active-BG-color; /* primary-700 */
}
/* Disabled */
.button:disabled {
  opacity: 0.4;
}

.pushStarters_SpecsUploadedVideoHeading {
  position: absolute;
  top: 19.31vh;
  left: 10.55vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-sub-text-color; /* tertiary2-500 */
}

.pushStarters_SpecsUploadedVideoName {
  position: absolute;
  top: 26.18vh;
  left: 14.72vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-box-shadow-color; /* neutral-900 */
}

/* Container 8055 */
.pushStarters_ReviewVideoContainer {
  position: absolute;
  top: 81.6vh;
  left: 4.16vw;
  width: 50vw;
  height: 30.88vh;
  background: $base-input-focus-BG-color; /* white */
  border-radius: 0.83vw; /* border-m */
  box-shadow: 0px 0px 1px $base-box-shadow-color,
    0px 0px 2px $base-box-shadow-color; /* shadow-xs */
  overflow-x: hidden;
  overflow-y: auto;
}

.pushStarters_ReviewVideoHeadingText {
  position: absolute;
  top: 0vh;
  left: 2.5vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.8vw;
  line-height: 4.87vh;
  color: $base-box-shadow-color; /* neutral-900 */
}

.pushStarters_ReviewVideoSubHeadingText {
  position: absolute;
  top: 5.48vh;
  left: 2.5vw;
  width: 44.44vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-box-shadow-color; /* neutral-900 */
}

/* Button 4280 */
.button {
  position: absolute;
  top: 13.27vh;
  left: 20.41vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.25vw;
  line-height: 3.41vh;
  color: $base-input-focus-BG-color; /* white */
  background: $base-troopod-color; /* primary-500 */
  opacity: 1;
  border: none;
  border-radius: 0.41vw; /* border-m */
}
/* Hover */
.button:hover {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-hover-BG-color; /* primary-600 */
}
/* Pressed */
.button:hover:active {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-active-BG-color; /* primary-700 */
}
/* Disabled */
.button:disabled {
  opacity: 0.4;
}

.pushStarters_ReviewUploadedVideoText {
  position: absolute;
  top: 19.31vh;
  left: 10.55vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-sub-text-color; /* tertiary2-500 */
}

.pushStarters_ReviewUploadedVideoName {
  position: absolute;
  top: 26.18vh;
  left: 14.72vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: $base-box-shadow-color; /* neutral-900 */
}

/* Container 7918 */
.uploadProgress {
  position: relative;
  bottom: 8vh;
  left: 23.33vw; /* primary-100 */
  border-radius: 0.83vw; /* border-xl */
  border-width: 0.06vw;
  border-color: $base-troopod-color; /* primary-500 */
}

/* Button 4277 */
.pushStarters_VideoPageBackButton {
  position: absolute;
  top: 114.93vh;
  left: 17.73vw;
  width: 6.25vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.25vw;
  line-height: 3.41vh;
  color: $base-troopod-color; /* primary-500 */
  background: $base-input-focus-BG-color; /* white */
  opacity: 1;
  border-radius: 0.41vw; /* border-m */
  border-width: 0.06vw;
  border-color: $base-troopod-color; /* primary-500 */
  border-style: solid;
  cursor: pointer;
}

.pushStarters_Create_Story_VideoPageBack {
  position: absolute;
  top: 114.93vh;
  left: 22.22vw;
  width: 6.25vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.25vw;
  line-height: 3.41vh;
  color: $base-troopod-color; /* primary-500 */
  background: $base-input-focus-BG-color; /* white */
  opacity: 1;
  border-radius: 0.41vw; /* border-m */
  border-width: 0.06vw;
  border-color: $base-troopod-color; /* primary-500 */
  border-style: solid;
  cursor: pointer;
}
/* Hover */
.pushStarters_VideoPageBackButton:hover {
  color: $base-button-hover-BG-color; /* primary-600 */
  background: $base-input-focus-BG-color; /* white */
}
/* Pressed */
.pushStarters_VideoPageBackButton:hover:active {
  color: $base-button-active-BG-color; /* primary-700 */
  background: $base-input-focus-BG-color; /* white */
}
/* Disabled */
.pushStarters_VideoPageBackButton:disabled {
  opacity: 0.4;
}

/* Button 4276 */
.pushStarters_Create_Story_VideoPageNext {
  position: absolute;
  top: 114.93vh;
  left: 29.86vw;
  width: 6.25vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.25vw;
  line-height: 3.41vh;
  color: $base-input-focus-BG-color; /* white */
  background: $base-troopod-color; /* primary-500 */
  opacity: 1;
  border: none;
  cursor: pointer;
  border-radius: 0.41vw; /* border-m */
}

.pushStarters_VideoPageNextButton {
  position: absolute;
  top: 114.93vh;
  left: 25.06vw;
  width: 6.25vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.25vw;
  line-height: 3.41vh;
  color: $base-input-focus-BG-color; /* white */
  background: $base-troopod-color; /* primary-500 */
  opacity: 1;
  border: none;
  cursor: pointer;
  border-radius: 0.41vw; /* border-m */
}

.pushStarters_VideoPageSkipButton {
  position: absolute;
  top: 114.93vh;
  left: 32.5vw;
  width: 6.25vw;
  height: 4.38vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.25vw;
  line-height: 3.41vh;
  color: $base-input-focus-BG-color; /* white */
  background: $base-troopod-color; /* primary-500 */
  opacity: 1;
  border: none;
  cursor: pointer;
  border-radius: 0.41vw; /* border-m */
}
/* Hover */
.pushStarters_VideoPageNextButton:hover {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-hover-BG-color; /* primary-600 */
}
/* Pressed */
.pushStarters_VideoPageNextButton:hover:active {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-active-BG-color; /* primary-700 */
}
/* Disabled */
.pushStarters_VideoPageNextButton:disabled {
  opacity: 0.4;
}

.checkVideoUpload {
  display: flex;
  width: 1.66vw;
  position: relative;
  height: 2.92vh;
  bottom: 8.64vh;
  left: 25.34vw;
}

/*********************************************************/

/* Edit Page */

.pushStarters_EditStoriesHeadingText {
  position: absolute;
  top: 0vh;
  left: 26.8vw;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 2.22vw;
  line-height: 5.84vh;
  color: $base-box-shadow-color; /* neutral-900 */
}

.pushStarters_EditStoriesSubHeadingText {
  position: absolute;
  top: 5.96vh;
  left: 17.77vw;
  width: 22.7vw;
  height: 5.48vh;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: $base-font-size-normal-text;
  line-height: $base-line-height-normal-text;
  color: #565d6dff; /* neutral-600 */
}

/* Table 80 */
.pushStarters_EditVideoTableContainer {
  position: absolute;
  left: 1vw;
  top: 18.87vh;
  width: 58.2vw;
  opacity: 1;
  border-radius: 0.41vw; /* border-m */
  box-shadow: none; /* shadow-none */
}

/* Button 4281 */
.pushStarters_EditPageBackButton {
  position: absolute;
  top: 74.9vh;
  left: 17.43vw;
  width: 6.25vw;
  height: 4.5vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.25vw;
  line-height: 3.41vh;
  color: $base-troopod-color; /* primary-500 */
  background: $base-input-focus-BG-color; /* white */
  opacity: 1;
  border-radius: 0.41vw; /* border-m */
  border-width: 0.06vw;
  border-color: $base-troopod-color; /* primary-500 */
  border-style: solid;
}
/* Hover */
.pushStarters_EditPageBackButton:hover {
  color: $base-button-hover-BG-color; /* primary-600 */
  background: $base-input-focus-BG-color; /* white */
}
/* Pressed */
.pushStarters_EditPageBackButton:hover:active {
  color: $base-button-active-BG-color; /* primary-700 */
  background: $base-input-focus-BG-color; /* white */
}
/* Disabled */
.pushStarters_EditPageBackButton:disabled {
  opacity: 0.4;
}

/* Button 4019 */
.pushStarters_EditPageDraftButton {
  position: absolute;
  top: 74.9vh;
  left: 25.06vw;
  width: 7.16vw;
  height: 4.5vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif; /* Body */
  font-size: 1.25vw;
  line-height: 3.41vh;
  color: $base-troopod-color; /* primary-500 */
  background: $base-input-focus-BG-color; /* white */
  opacity: 1;
  border-radius: 0.41vw; /* border-m */
  border-width: 0.06vw;
  border-color: $base-troopod-color; /* primary-500 */
  border-style: solid;
}
/* Hover */
.pushStarters_EditPageDraftButton:hover {
  color: $base-button-hover-BG-color; /* primary-600 */
  background: $base-input-focus-BG-color; /* white */
}
/* Pressed */
.pushStarters_EditPageDraftButton:hover:active {
  color: $base-button-active-BG-color; /* primary-700 */
  background: $base-input-focus-BG-color; /* white */
}
/* Disabled */
.pushStarters_EditPageDraftButton:disabled {
  opacity: 0.4;
}

/* Button 4018 */
.pushStarters_EditPageFinishButton {
  position: absolute;
  top: 74.8vh;
  left: 33.49vw;
  width: 6.25vw;
  height: 4.45vh;
  padding: 0 0.83vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font-family-sans-serif;
  font-size: 1.25vw;
  line-height: 3.41vh;
  color: $base-input-focus-BG-color;
  background: $base-troopod-color;
  opacity: 1;
  border: none;
  border-radius: 0.41vw; /* border-m */
}
/* Hover */
.pushStarters_EditPageFinishButton:hover {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-hover-BG-color; /* primary-600 */
}
/* Pressed */
.pushStarters_EditPageFinishButton:hover:active {
  color: $base-input-focus-BG-color; /* white */
  background: $base-button-active-BG-color; /* primary-700 */
}
/* Disabled */
.pushStarters_EditPageFinishButton:disabled {
  opacity: 0.4;
}
/**********************************************/

/* Existing story */

.storyDropdown {
  position: absolute;
  top: 63.94vh;
  left: 13.88vw;
  opacity: 1;

  select {
    position: relative;
    top: 3.65vh;
    right: 6.25vw;
    width: 30.55vw;
    height: 4.38vh;
    padding-left: 0.83vw;
    padding-right: 2.36vw;
    font-family: $base-font-family-sans-serif; /* Body */
    font-size: $base-font-size-normal-text;
    color: $base-box-shadow-color; /* neutral-900 */
    background: $base-input-focus-BG-color; /* white */
    border-radius: 0.41vw; /* border-m */
    border-width: 0.06vw;
    border-color: $base-otp-input-border-color; /* neutral-500 */
    outline: none;
  }
  svg {
    position: absolute;
    top: 1.46vh;
    right: 0.83vw;
    width: 1.11vw;
    height: 1.94vh;
    fill: $base-box-shadow-color; /* neutral-900 */
  }
  .storylabel {
    font-size: $base-font-size-normal-text;
    line-height: $base-line-height-normal-text;
    font-family: $base-font-family-sans-serif;
    font-weight: $base-font-weight-bold;
  }
  /* hover */
  select:hover {
    color: $base-input-color; /* neutral-400 */
    background: $base-input-focus-BG-color; /* white */
    border-color: $base-input-hover-border-color; /* neutral-550 */
  }
  /* focused */
  select:focused {
    color: $base-input-color; /* neutral-400 */
    background: $base-input-focus-BG-color; /* white */
    border-color: $base-otp-input-border-color; /* neutral-500 */
  }
  /* disabled */
  select:disabled {
    color: $base-input-color; /* neutral-400 */
    background: $base-input-focus-BG-color; /* white */
    border-color: $base-otp-input-border-color; /* neutral-500 */
  }
}
.storyDropdown :disabled + .icon,
.storyDropdown :disabled + .icon + .icon {
  fill: $base-box-shadow-color; /* neutral-900 */
}
